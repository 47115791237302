@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@font-face {
  font-family: 'Gotham Rounded';
  src: url('fonts/GothamRounded-Medium.woff2') format('woff2'),
      url('fonts/GothamRounded-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Rounded Book';
  src: url('fonts/GothamRounded-Book.woff2') format('woff2'),
      url('fonts/GothamRounded-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Rounded';
  src: url('fonts/GothamRounded-Light.woff2') format('woff2'),
      url('fonts/GothamRounded-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}


body {
  margin: 0;
  font-family: 'Gotham Rounded';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.offer-banner .animate-spin {animation-duration:25s;}
/* .offer-banner .slick-slider {display:flex;} */
.offer-banner .slick-slider {display:block;margin-top: 50px;}


.rc-slider-track {background-color:#E56200 !important;}
.rc-slider-handle {border-color:#E56200 !important;}
.rc-slider-mark-text {color:#000 !important;display:block !important;transform:translate(0) !important;}
.rc-slider-mark {left:0px !important;right:0px !important;}
.rc-slider-mark span:last-child {left:auto !important;right:0px !important;}


select {
  background: #fff;
}

@media (max-width:767px) {
  .offer-banner .slick-slider {display:block;}
  /* .offer-banner .slick-track ,.offer-banner .slick-slide {width:100% !important;} */
}

.pagination {display: flex;justify-content: center;}
.pagination a {padding: 10px;margin: 8px;border-radius: 5px;border: 1px solid #E56200;color: #E56200;cursor: pointer;}
.pagination a:hover {color: #fff;background-color: #E56200;}
.pagination .paginationActive a {color: #fff;background-color: #E56200;}




.product-slide .badge {
  top: 15px;
}

.stats .slick-slider{
  overflow: hidden;
}
.stats .item{
 display: inline-flex !important;
}
.stats .item .flex{
  padding-right: 20px;
  padding-bottom: 15px;
}


.empty-ico{
  width: 200px;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 30px;
}

.no-data{
  height: 69vh;
  /* align-items: center; */
  padding-top: 7vh;
}
.no-data svg{
  text-align: center;
}
.categories-page .shadow-xl {
  border: 1px solid #f5f5f5;
  text-align: center !important;
}

.details{
  position: relative;
}
.badge {
  position: absolute;
  top: -35px;
  left: 15px;
  background: #4b8309;
  color: #ffffff;
  font-size: 11px;
  line-height: 1;
  text-align: center;
  z-index: 9;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 5px 8px;
}

.text-gray{
  color: #a09e9e!important;
}
@media all and (max-width: 768px) {
.stats .item .flex{
  padding-bottom: 0px;
}
.m-none{
  display: none;
}

.section-50{
  padding: 50px 0px;
}
.ms-15-section{
  padding: 15px 0px !important;
}
.ms-25-section{
  padding: 25px 0px !important;
}
.ms-0-section{
  padding: 0px 0px !important;
}
.m-pb-80{
  padding-bottom: 80px !important;
}
.m-pt-20-0{
  padding-top: 20px;
  padding-bottom: 10px;
}
.logo{
  height: 4rem;
  padding-right: 10px;
}

.search input{
  font-size: 14px;
}
.m-100{
  width: 100% !important;
}
.m-footer .nav-item{
  text-align: center;
  padding: 8px 0px;
}
.m-footer .nav-item h6{
  color: #333;
  font-size: 12px;
}
.m-footer .ft-ico{
  display: inline-block;
  margin-bottom: 4px;
}

.m-footer {
  width: 100%;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: 12;
  background: #fff;
  box-shadow: 0px 1px 10px rgb(0 0 0 / 10%), 0px -1px 10px rgb(0 0 0 / 10%);
}


.m-footer .active .ft-ico{
color: #ed2024;
}

body{
  padding-bottom: 50px;
}
.m-fs-14{
  font-size: 14px;
}
.m-fs-18{
  font-size: 18px;
}
.m-fs-24{
  font-size: 24px;
}
.m-mb-5{
  margin-bottom: 10px;
}




.testmonial .slick-slide p{
  font-size: 18px;
}

/* .-bottom-20 {
  bottom: -3rem;
} */








.m-pt-0 {
  padding-top: 0px;
}
.m-pt-5 {
  padding-top: 5px;
}
.m-pt-10 {
  padding-top: 10px;
}
.m-pt-20 {
  padding-top: 20px;
}
.m-pt-30 {
  padding-top: 30px;
}

.m-pt-40 {
  padding-top: 40px;
}
.m-pt-50 {
  padding-top: 50px;
}

.m-pt-60 {
  padding-top: 60px;
}

.m-pt-70 {
  padding-top: 70px;
}

.m-pt-80 {
  padding-top: 80px;
}

.m-pt-90 {
  padding-top: 90px;
}
.m-pt-100 {
  padding-top: 100px;
}




.m-pb-0 {
  padding-bottom: 0px;
}
.m-pb-5 {
  padding-bottom: 5px;
}
.m-pb-10 {
  padding-bottom: 10px;
}
.m-pb-20 {
  padding-bottom: 20px;
}
.m-pb-30 {
  padding-bottom: 30px;
}

.m-pb-40 {
  padding-bottom: 40px;
}
.m-pb-50 {
  padding-bottom: 50px;
}

.m-pb-60 {
  padding-bottom: 60px;
}
.m-pb-70 {
  padding-bottom: 70px;
}

.m-pb-80 {
  padding-bottom: 80px;
}
.m-pb-90 {
  padding-bottom: 90px;
}
.m-pb-100 {
  padding-bottom: 100px;
}





.m-mb-0{
  margin-bottom: 0px;
}
.m-mb-5{
  margin-bottom: 5px;
}
.m-mb-10{
  margin-bottom: 10px;
}
.m-mb-20{
  margin-bottom: 20px;
}
.m-mb-30{
  margin-bottom: 30px;
}
.m-mb-40{
  margin-bottom: 40px;
}
.m-mb-50{
  margin-bottom: 50px;
}
.m-mb-60{
  margin-bottom: 60px;
}
.m-mb-70{
  margin-bottom: 70px;
}
.m-mb-80{
  margin-bottom: 80px;
}
.m-mb-90{
  margin-bottom: 90px;
}
.m-mb-100{
  margin-bottom: 100px;
}




.m-mt-0{
  margin-top: 0px;
}
.m-mt-5{
  margin-top: 5px;
}
.m-mt-10{
  margin-top: 10px;
}
.m-mt-20{
  margin-top: 20px;
}
.m-mt-30{
  margin-top: 30px;
}
.m-mt-40{
  margin-top: 40px;
}
.m-mt-50{
  margin-top: 50px;
}
.m-mt-60{
  margin-top: 60px;
}
.m-mt-70{
  margin-top: 70px;
}
.m-mt-80{
  margin-top: 80px;
}
.m-mt-90{
  margin-top: 90px;
}
.m-mt-100{
  margin-top: 100px;
}



.mbtn{
  font-size: 14px;
}



.m-border-0{
  border:0px !important;
}


.categories-page .text-lg {
  font-size: 14px;
  line-height: normal;
}

}



.mst .item img{
  width: 50px;
}

.mst .item{
  width: 100%;
  align-items: center;
  display: flex;
  margin-bottom: 15px;
  background: #f5f5f5;
  border-radius: 10px;
  padding: 15px 15px;
}

.mst .item h6{
  width: 100%;
  overflow: hidden;
  margin-left: 10px;
}

.item .bg-gray-300 {
  width: 300px !important;
}

@media all and (max-width: 768px) {
  .mst .item{
    width: 100%;
    align-items: center;
    display: inline-block;
    margin-bottom: 15px;
    background: #f5f5f5;
    border-radius: 10px;
    padding: 15px 15px;
  }

  .item .bg-gray-300 {
    margin-top: 15px;
    width: 100% !important;
  }
  

  
  .item .text-sm{
    display: inline-block;
  }

  .mnt {
    margin-bottom: 15px;
  }

  .whatsapp.w-12 {
    width: 40px;
}
  .whatsapp.h-12 {
    height: 40px;
}
  .whatsapp.bottom-8 {
    bottom: 70px;
}
  .whatsapp.right-8 {
  right: 10px;
}

  .offer-banner .h-96 {
  height: 55vh;
}
}


.status-Pending{
  background-color: #9EA7AD !important;
}
.status-Confiremd{
  background-color: #2DCCFF !important;
}
.status-Making{
  background-color: #56F000 !important;
}
.status-Ready{
  background-color: #48C304 !important;
}
.status-On{
  background-color: #399D01 !important;
}
.status-Deliverd{
  background-color: #2C7901 !important;
}
.status-Cancelled{
  background-color: #FF3838 !important;
}
.status-Undeliverd{
  background-color: #FFB302 !important;
}

.mnt {
  width: 100%;
  display: flex;
  align-items: center;
}

.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}