@tailwind base;
@tailwind components;
@tailwind utilities;



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
